<template>
  <div v-if="!isFetching" class="empty-container" :class="[size, type]">
    <img loading="lazy" :src="require('@/assets/images/svg/' + img)" :alt="$t.getTranslation('LBL_EMPTY_IMAGE')" />
    <p v-if="!hideTitle">{{ title || $t.getTranslation("LBL_CHAT_CS") }}</p>
    <f7-button href="/profile/settings/wine/" v-if="wine" fill>{{ $t.getTranslation("LBL_WINE_PREFERENCE") }}</f7-button>

    <f7-button v-if="button" :href="buttonLink" fill large>{{ buttonText }}</f7-button>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "EmptyContainerComponent",
  props: {
    wine: Boolean,
    size: String,
    type: String,
    img: {
      type: String,
      default: "empty.svg",
    },
    title: String,
    hideTitle: { type: Boolean, default: false },
    button: { type: Boolean, default: false },
    buttonText: String,
    buttonLink: String,
  },
  setup(props) {
    const store = useStore();
   
    const isFetching = computed(() => store.getters["page/getData"]?.isFetching);

    return {
      isFetching,
    };
  },
});
</script>
<style>
.empty-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0px 0px;
  width: 100%;
}
.empty-container img {
  width: 260px;
}
.empty-container p {
  font-size: 24px;
  margin-top: 30px;
}
/* SMALL */
.empty-container.sm {
  padding: 60px 0px 0px;
}
.empty-container.sm img {
  width: 160px;
}
.empty-container.sm p {
  font-size: 16px;
  margin-top: 20px;
}
/* FULL */
.empty-container.full {
  height: calc(100vh - 300px);
  padding: 0px;
}
/* SHEET */
.empty-container.sheet {
  height: calc(100vh - 90px);
  padding: 0px;
}
.empty-container .button {
  min-width: 160px;
}
</style>
